import { useState } from 'react';
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
  useToast
} from '@chakra-ui/react';

import { Header } from '../../components/header/Header';
import { CpfForm } from '../../components/cpf-form/CpfForm';
import { PageTitle } from '../../components/pageTitle/PageTitle';
import { useClientData } from '../../hooks/client/useClientData';

export const CheckClient = () => {
  const toast = useToast();
  const [clientData, setClientData] = useState("")
  const { getClientData, loading } = useClientData();

const onClickCpf = async (response) => {
  setClientData(null);
  const { cpf, message } = response;
  if (message) {
    toast({
      title: 'error',
      description: message,
      status: 'error',
      duration: 7000,
      isClosable: true,
      position: "top",
    });
    return;
  }

  const payload = { documento: cpf };
  const result = await getClientData(payload);

  if (result.errorMessage) {
    toast({
      title: 'error',
      description: `${result.errorMessage}`,
      status: 'error',
      duration: 7000,
      isClosable: true,
      position: "top",
    });
  } else {
    setClientData(result);
  }
}

const renderingClientData = () => {
    if (!clientData) {
      return (
        <Box my="4" mx="auto" p="15px" w="500px" border="1px" borderRadius="10px" borderColor="gray.200" align="center">
          Dados inexistentes
        </Box>
      );
    }
    
    return(
      <>
        <TableContainer my="25px" mx="auto" p="15px" w="650px" border="1px" borderRadius="10px" borderColor="gray.200">
          <Table variant='striped'>
            <Thead><Tr><Td>Telefones:</Td></Tr></Thead>
            <Tbody>
              { clientData.telefones.map((telefone, index) => (
                  <Tr key={ index }>
                    <Td>Telefone:</Td>
                    <Td>{ telefone.telefone }</Td>
                    <Td>Procon: { telefone.procon  ? "Sim" : "Não" }</Td>
                  </Tr>
                ))
              }
            </Tbody>
          </Table>
        </TableContainer>

        <TableContainer my="25px" mx="auto" p="15px" w="650px" border="1px" borderRadius="10px" borderColor="gray.200">
          <Table variant='striped'>
            <Thead><Tr><Td>Dados Cadastrais:</Td></Tr></Thead>
            <Tbody>
              <Tr>
                <Td>Nome:</Td>
                <Td>{ clientData.cadastrais.nome } </Td>
              </Tr>
              <Tr>
                <Td>CPF:</Td>
                <Td>{ clientData.cadastrais.cpf } </Td>
              </Tr>
              <Tr>
                <Td>Idade:</Td>
                <Td>{ clientData.cadastrais.idade } </Td>
              </Tr>
              <Tr>
                <Td>Data de Nascimento:</Td>
                <Td>{ clientData.cadastrais.nasc } </Td>
              </Tr>
              <Tr>
                <Td>Genero:</Td>
                <Td>{ clientData.cadastrais.sexo === "M" ? "Masculino" : "Feminino" } </Td>
              </Tr>
              <Tr>
                <Td>Óbito:</Td>
                <Td>{ clientData.obito.flag_obito ? "Sim" : "Não" } </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <TableContainer my="25px" mx="auto" p="15px" w="650px" border="1px" borderRadius="10px" borderColor="gray.200">
          <Table variant='striped'>
            <Thead><Tr><Td>Emails:</Td></Tr></Thead>
            <Tbody>
              { clientData.emails.map((email, index) => (
                  <Tr key={ index }>
                    <Td>Email:</Td>
                    <Td>{ email.emails }</Td>
                  </Tr>
                ))
              }
            </Tbody>
          </Table>
        </TableContainer>

        <TableContainer my="25px" mx="auto" p="15px" w="650px" border="1px" borderRadius="10px" borderColor="gray.200">
          <Table variant='striped'>
            <Thead><Tr><Td>Endereços:</Td></Tr></Thead>
            { clientData.enderecos.map((endereco, index) => (
                <Tbody key={ index }>
                  <Tr>
                    <Td>Logradouro:</Td>
                    <Td>{ endereco.logradouro }</Td>
                  </Tr>
                  <Tr>
                    <Td>Número:</Td>
                    <Td>{ endereco.numero }</Td>
                  </Tr>
                  <Tr>
                    <Td>Complemento:</Td>
                    <Td>{ endereco.complemento }</Td>
                  </Tr>
                  <Tr>
                    <Td>Bairro:</Td>
                    <Td>{ endereco.bairro }</Td>
                  </Tr>
                  <Tr>
                    <Td>Cep:</Td>
                    <Td>{ endereco.cep }</Td>
                  </Tr>
                  <Tr>
                    <Td>Cidade:</Td>
                    <Td>{ endereco.cidade }</Td>
                  </Tr>
                  <Tr>
                    <Td>Estado:</Td>
                    <Td>{ endereco.uf }</Td>
                  </Tr>
                </Tbody>
              ))
            }
          </Table>
        </TableContainer>
      </>
    );
  }

  return (
    <>
      <Header />
      <PageTitle title="Consultar Dados Cadastrais Por CPF" />
      <CpfForm loading={ loading } onClickCpf={ onClickCpf } />
      { renderingClientData() }
    </>
  );
}
