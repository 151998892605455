import axios from 'axios';

const url = process.env.REACT_APP_API_RUBENS_URL;
const TIMEOUT = 20000;

const errorResponse = (error) => {
  if (error.code === 'ERR_NETWORK') {
    return { errorMessage: 'Problemas ao tentar acessar o servidor' };
  }
  
  else if (error.code === 'ECONNABORTED') {
    return { errorMessage: `O tempo limite de espera ${TIMEOUT / 1000} segundos foi excedido.` };
  }
  
  else if (error.response) {
    if (error.response.data.mensagem) {
      return { errorMessage: `${ error.response.data.erro }: ${ error.response.data.mensagem }` };
    }
  }
  return { errorMessage: error.message };
}

export const getClientDataApi = async (payload) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${url}/consulta`,
      data: payload,
      timeout: TIMEOUT,
    });
    return data;
  } catch (error) {
    return errorResponse(error);
  } 
}

export const getClientDataByPhoneNumberApi = async (payload) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${url}/consulta/telefone`,
      data: payload,
      timeout: TIMEOUT,
    });
    return data;
  } catch (error) {
    return errorResponse(error);
  }
}
