import { useState } from 'react';
import { object, string } from 'yup';

import { getClientDataApi, getClientDataByPhoneNumberApi } from '../../services/client/clientAPI';

export const useClientData = () => {
  const [loading, setLoading] = useState(false);

  const checkPayload = async (payload) => {
    const schema = object().shape({
      telefone: string().required().min(15),
    });
  
    try {
      return await schema.validate(payload);
    } catch (error) {
      return { errorMessage: `'${error.path}' ${error.message}` };
    }
  }

  const getClientData = async (payload) => {
    const result = await getClientDataApi(payload);
    setLoading(false);
    return result;
  };

  const getClientDataByPhoneNumber = async (payload) => {
    setLoading(true);

    const isValidated = await checkPayload(payload);
    if (isValidated.errorMessage) {
      setLoading(false);
      return isValidated;
    }

    setLoading(true);
    const result = await getClientDataByPhoneNumberApi(payload);
    setLoading(false);
    return result;
  };

  return {
    loading,
    getClientData,
    getClientDataByPhoneNumber,
  };
};
